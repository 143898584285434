import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { TopMenu } from './TopMenu'
import { BottomMenu } from './BottomMenu'

export const Layout = ({
  children,
  menu,
  activeDocMeta,
  metaTitle,
  metaDescription,
  metaImage,
}) => {
  const queryData = useStaticQuery(graphql`
    query SiteQuery {
      prismicGlobal {
        data {
          meta_title
          meta_description
          favicon {
            url
          }
        }
      }
      prismicContactInfo {
        data {
          email
          facebook
          instagram
          linked_in
          phone_number
          twitter
        }
      }
    }
  `)
  console.log(queryData)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{metaTitle || queryData.prismicGlobal.data.meta_title}</title>
        <meta
          name="description"
          content={
            metaDescription || queryData.prismicGlobal.data.meta_description
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href={queryData.prismicGlobal.data.favicon.url} />
        {metaImage && <meta property="og:image" content={metaImage} />}
      </Helmet>
      <TopMenu menu={menu} activeDocMeta={activeDocMeta} />
      <main id="main">{children}</main>
      <BottomMenu menu={menu} socials={queryData.prismicContactInfo?.data} />
    </>
  )
}
