import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Layout } from '../components/Layout'
import { Container } from '../components/Container'

import { components } from '../slices'
import * as s from './styles/Article.module.scss'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  PinterestShareButton,
} from 'react-share'
import { FaLinkedin, FaPinterest, FaTwitter, FaFacebook } from 'react-icons/fa'

const ArticleTemplate = ({ data }) => {
  if (!data) return null

  const articleContent = data.prismicArticle || {}
  const article = articleContent.data || {}
  const menu = data.prismicMenu || {}
  const articlesData = data.allPrismicArticle || {}
  const articles = articlesData?.nodes || []

  const { lang, type, url } = articleContent || {}
  const alternateLanguages = articleContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const [email, setEmail] = React.useState('')
  const [result, setResult] = React.useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const Result = await addToMailchimp(email)
    setResult(Result)
  }
  const handleChange = (e) => {
    setEmail(event.target.value)
  }
  const curUrl = typeof window !== 'undefined' ? window?.location.href : url

  const metaTitle = article.meta_title || article.title?.text
  const metaDescription =
    article.meta_description || data.prismicGlobal?.data?.meta_description
  const metaImage =
    article.meta_image?.gatsbyImageData ||
    data.prismicGlobal?.data?.meta_image?.gatsbyImageData ||
    null

  return (
    <Layout
      menu={menu.data}
      activeDocMeta={activeDoc}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      metaImage={metaImage}
    >
      <section className={s.Article}>
        <Container>
          <nav aria-label="Breadcrumb" className={s.breadcrumb}>
            <ul>
              <li>
                <a href="/all">All Articles</a>
              </li>
              <li>
                <a href={article.main_category.url}>
                  {article.main_category.document?.data.name}
                </a>
              </li>
              <li>
                <a href="#" aria-current="page">
                  {article.title?.text}
                </a>
              </li>
            </ul>
          </nav>
          <div className={s.topContainer}>
            <div className={s.articleImage}>
              {article.featured_image && (
                <GatsbyImage
                  image={article.featured_image?.gatsbyImageData}
                  alt={article.featured_image?.alt || ''}
                />
              )}
            </div>
            <div className={s.articleHeader}>
              <span className={s.articleTime}>
                Time to read: {article.time}
              </span>
              <h1>{article.title?.text}</h1>
              <div className={s.articleMeta}>
                <span>{article.article_date}</span> &middot;{' '}
                <span className="subtitle">
                  {article.main_category.document?.data.name}
                </span>
              </div>
            </div>
          </div>
          <div className={s.bottomContainer}>
            <div className={s.articleContent}>
              <PrismicRichText field={article.article_text?.richText} />

              {article.end_of_article_banner?.document?.data?.link && (
                <div className={s.BottomBanner}>
                  <PrismicRichText
                    field={
                      article.end_of_article_banner?.document?.data?.banner_text
                        ?.richText
                    }
                  />
                  <PrismicLink
                    className={s.btnPrimary}
                    href={
                      article.end_of_article_banner?.document?.data?.link?.url
                    }
                  >
                    Learn More
                  </PrismicLink>
                </div>
              )}
            </div>
            <div className={s.stickyColumn}>
              <div className={s.shareBar}>
                <h2 className={s.H3}>Share This:</h2>
                <FacebookShareButton url={curUrl}>
                  <FaFacebook />
                </FacebookShareButton>
                <TwitterShareButton url={curUrl}>
                  <FaTwitter />
                </TwitterShareButton>
                <LinkedinShareButton url={curUrl}>
                  <FaLinkedin />
                </LinkedinShareButton>
                {/* <PinterestShareButton url={articleContent.url}><FaPinterest/></PinterestShareButton> */}
              </div>
              <div className={s.stickyBoxCenter}>
                {article.sidebar_banner?.document?.data?.link && (
                  <div className={s.SideBanner}>
                    <PrismicRichText
                      field={
                        article.sidebar_banner?.document?.data?.banner_text
                          ?.richText
                      }
                    />
                    <PrismicLink
                      className={s.btnPrimary}
                      href={article.sidebar_banner?.document?.data?.link?.url}
                    >
                      Learn More
                    </PrismicLink>
                  </div>
                )}
              </div>
              <div className={s.stickyBoxBottom}>
                <div className={s.Newsletter}>
                  <h2>Stay in the loop for more</h2>
                  <p>
                    You don't need another real estate newsletter; that's why
                    you need this one.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <input
                      id="NewsletterEmail"
                      name="NewsletterEmail"
                      type="email"
                      placeholder="Your Email"
                    />
                    <input
                      type="submit"
                      className={s.btnPrimary}
                      value="Sign Up"
                    />
                  </form>
                  {result?.result == 'success' ? (
                    <p>{result?.msg}</p>
                  ) : result?.result == 'error' ? (
                    <p>{result?.msg}</p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <SliceZone
        slices={article.body}
        components={components}
        context={{ articles: articles }}
      />
    </Layout>
  )
}

export default withPrismicPreview(ArticleTemplate)

export const query = graphql`
  query articleQuery($id: String, $lang: String) {
    prismicArticle(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      id
      data {
        meta_title
        meta_description
        meta_image {
          gatsbyImageData
          alt
        }
        main_category {
          url
          uid
          document {
            ... on PrismicArticleCategory {
              id
              data {
                name
              }
            }
          }
        }
        subcategories {
          category {
            document {
              ... on PrismicArticleCategory {
                id
                data {
                  name
                }
              }
            }
          }
        }
        title {
          text
        }
        time
        article_date
        featured_image {
          gatsbyImageData
          alt
        }
        article_text {
          richText
        }
        end_of_article_banner {
          document {
            ... on PrismicActionableBanner {
              data {
                banner_image {
                  gatsbyImageData
                  alt
                }
                banner_text {
                  richText
                }
                link {
                  url
                }
              }
            }
          }
        }
        sidebar_banner {
          document {
            ... on PrismicActionableBanner {
              data {
                banner_image {
                  gatsbyImageData
                  alt
                }
                banner_text {
                  richText
                }
                link {
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...ArticleDataBodySummaryListing
          ...ArticleDataBodyCategoriesWithImage
          ...ArticleDataBodyThreeColumnListing
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
      ...BottomMenuFragment
    }
    allPrismicArticle {
      nodes {
        data {
          article_date
          article_text {
            richText
          }
          main_category {
            uid
          }
          featured
          featured_image {
            gatsbyImageData
            alt
          }
          time
          title {
            richText
            text
          }
        }
      }
    }
    prismicGlobal {
      data {
        meta_title
        meta_description
        favicon {
          url
        }
      }
    }
  }
`
